<template>
  <div class="content asset-card-list">
    <section style="margin-bottom: 1%">
      <a-button type="primary" @click="createHandle">
        <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
        {{ $t('新增资产卡片') }}
      </a-button>
    </section>
    <div class="form-content">
      <a-row :gutter="16" style="margin-top: 15px">
        <a-col :span="6">
          <CommonSelect
            :isBu="true"
            mode="multiple"
            :placeholder="$t('货主组织')"
            :list="$store.state.settlementBuList"
            :code.sync="formData.ownerBusinessUnitCodeList"
          />
        </a-col>
        <a-col :span="6">
          <a-button @click="searchHandle(1)" type="primary">
            <i class="iconfont" style="margin-right: 5px">&#xe647;</i>
            {{ $t(`查询`) }}
          </a-button>
          <a-button @click="reset">
            <a-icon type="redo" />
            {{ $t(`重置`) }}
          </a-button>
        </a-col>
      </a-row>
    </div>
    <CommonTable
      ref="CommonTableRef"
      :statusList="$store.state.asset_card_status"
      :columns.sync="columns"
      :loading="loading"
      :dataSource="tableData"
      :showSelect="true"
      :scroll="{ x: 1500 }"
      :selectedRowKeys.sync="selectedRowKeys"
      :total="total"
      rowKey="id"
      @select="onSelectChange"
      @search="getList"
    >
      <template v-slot:header>
        <div class="flex-ct">
          <OperateBtn
            api="operateAssetCard"
            :text="$t('审核')"
            :params="{
              action: 'CHECK',
              idList: selectedRowKeys,
            }"
            :disabled="selectedRows.length === 0"
            @success="searchHandle"
          />
          <VoucherBtn
            class="ml10"
            :docType="docType"
            :selectedRows="selectedRows"
            :disabled="selectedRows.length === 0"
            @close="searchHandle"
          />
        </div>
      </template>
      <template v-slot:assetCode="{ text, record }">
        <a @click="viewDetail(record)">{{ text }}</a>
      </template>
    </CommonTable>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import CommonTable from '@/component/commonTable'
import { convertKeysToCamelCase } from '@/common'
export default {
  name: 'assetCardList',
  components: { CommonTable },
  data() {
    return {
      docType: 'AssetCard',
      formData: {
        ownerBusinessUnitCodeList: [],
      },
      tableData: [],
      selectedRowKeys: [],
      selectedRows: [],
      total: 0,
      loading: false,

      columns: [
        {
          title: this.$t('货主组织'),
          dataIndex: 'ownerBusinessUnitName',
        },
        {
          title: this.$t('资产组织'),
          dataIndex: 'userBusinessUnitName',
        },
        {
          title: this.$t('资产类别'),
          dataIndex: 'assetTypeName',
        },
        {
          title: this.$t('卡片编号'),
          dataIndex: 'cardNo',
          width: 200,
        },
        {
          title: this.$t('资产编码'),
          dataIndex: 'assetCode',
          width: 200,
          scopedSlots: { customRender: 'assetCode' },
        },
        {
          title: this.$t('资产名称'),
          dataIndex: 'assetName',
        },
        {
          title: this.$t('开始使用日期'),
          dataIndex: 'startUseDate',
          customRender: (text) => text?.split(' ')?.[0],
        },
        {
          title: this.$t('数量'),
          dataIndex: 'quantity',
        },
        {
          title: this.$t('计量单位'),
          dataIndex: 'unitName',
        },
        {
          title: this.$t('单据状态'),
          dataIndex: 'status',
          customRender: (text) => this.$store.getters.assetCardStatusMapping[text],
        },
        {
          title: this.$t('是否生成凭证'),
          dataIndex: 'voucherGenerated',
          width: 100,
          customRender: (text) => (text ? this.$t('是') : this.$t('否')),
        },
        {
          title: this.$t('凭证号'),
          dataIndex: 'voucherNo',
          width: 100,
        },
      ],
    }
  },
  activated() {
    this.searchHandle()
  },
  methods: {
    ...mapMutations(['addPane']),
    reset() {
      Object.assign(this.$data, this.$options.data())
      this.searchHandle(1)
    },
    searchHandle(pageNo) {
      this.selectedRows = []
      this.selectedRowKeys = []
      this.$refs.CommonTableRef.queryList(pageNo)
    },
    createHandle() {
      this.$router.push({ name: 'assetCardDetail' })
    },
    async getList(params) {
      this.loading = true
      const data = {
        ...this.formData,
        ...params,
      }
      if (params.status) {
        data.statusList = [params.status]
      }
      await http({
        url: api.getAssetCardList,
        data,
        success: (res) => {
          let result = convertKeysToCamelCase(res.result)
          this.tableData = result.list
          this.total = res.result.total
        },
      })
      this.loading = false
    },
    viewDetail(record) {
      this.addPane({
        view: 'assetCardDetail/' + record.assetCode,
        name: 'detailSn',
        query: {
          id: record.id,
          sn: record.assetCode,
        },
        route: `/asset/assetCardDetail?id=${record.id}&sn=${record.assetCode}`,
      })
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
  },
}
</script>
